<template>
  <div class="container">
    <div 
      @click="backToNewsList()"
      class="navi">
      <span class="arrow-left"></span>
      <a>ニュース一覧へ戻る</a>
    </div>
    <div class="content">
      <div>
        <span class="date">{{newsDetail.date}}</span>
      </div>
      <div class="title">{{newsDetail.title}}</div>
      <div class="article" v-html="newsDetail.content" />
    </div>
    <div class="btns">
      <span class="arrow-left"></span>
      <span 
        @click="toPrevious"
        class="btn btn-left">Prev</span>
      <span 
        @click="toNext"
        class="btn btn-right">Next</span>
      <span class="arrow-right"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'News',
  components: {
  },
  data() {
    return {
      newsDetail: {
        title: '',
        date: '',
        content: '',
      },
      fileName: '',
      newsListHost: 'https://cozhowm9fh.execute-api.ap-northeast-1.amazonaws.com/default/buybuy-news',
      newsDetailHost: 'https://d1j55bwcpyrn6a.cloudfront.net',
    }
  },
  created() {
    const id = this.$route.params.id
    this.$api.get(this.newsListHost).then(res => {
      this.fileName = res.data.find(news => news.name.split('_')[0] === id).name
      this.$api.get(`${this.newsDetailHost}/${this.fileName}`).then(res => {
        this.newsDetail = res.data
      })
    })
  },
  methods: {
    backToNewsList() {
      location.href = '/news';
    }
  }
}
</script>

<style lang="scss">
.container {
  width: 80%;
  margin: 100px auto 60px auto; 
  padding-bottom: 30px;
  .navi {
    display: flex;
    margin: 10px auto 10px auto;
    align-items: center;
    cursor: pointer;
    &:hover {
      color: #F2B504;
    }
    .arrow-left {
      border: solid #F2B504;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 6px;
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      margin-right: 3px;
      margin-left: 10px;
    }
  }
  .content {
    margin: 20px 0 40px 0;
    background-color: #F6F6F6;
    border-radius: 5px;
    padding: 50px;
    .date {
      font-size: 18px;
      color: #F2B504;
      font-weight: bold;
    }
    .title {
      margin-bottom: 20px;
      font-weight: bold;
      font-size: 20px;
      color: rgb(71, 67, 67);
    }
    .article {
      padding: 20px 5px 20px 5px;
    }
  }
  .btns {
    display: flex;
    justify-content: center;
    align-items: center;
    .btn {
      background: #F7F7F7;
      padding: 5px 15px;
      margin: 0 10px;
      box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
      cursor: pointer;
    }
    .btn-left {
      margin-right: 20px;
    }
    .btn-right {
      margin-left: 20px;
    }
    .arrow-left {
      border: solid #F2B504;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 6px;
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      margin-top: 5px;
    }
    .arrow-right {
      border: solid #F2B504;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 6px;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      margin-top: 5px;      
    }        
  }
}

@media screen and (max-width: 750px) {
  .container {
    width: 90%;
    .content {
      padding: 20px;
      padding-top: 50px;
      .title {
        margin-top: 10px;
      }
    }
  }
}
</style>